import Screen from './screen';
import { connect } from 'react-redux';

const mapStateToProps = statte => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);