import { Accordion,Card,Button } from 'react-bootstrap';
import React,{useState} from 'react';
import './styles.scss';
import { IMAGES } from '../../../shared';
import classNames from 'classnames';

const CasinoAccordionItem = ({
    active,
    index,
    onClick = () => { },
}) => {
    const [paymentCollapsed, setPaymentCollapsed] = useState(true);
    return(
        <Accordion defaultActiveKey="0">
        <div class="tpbox-Wrapse">
            <div class="tprib-Mainse red">
                <div class="tprib-Angse"><i class="fa fa-caret-down" aria-hidden="true"></i> <i class="fa fa-caret-down ryt-Se" aria-hidden="true"></i> </div>
                <div class="tprib-text" >
                    <p>HETT</p>
                </div>
            </div>
            <div class="list-Boxwrap">
                <div class="top-Listse">
                    <div class="row no-gutters align-items-center1">
                        <div class="col-lg-3">
                            <div class="logo-Topse bg-ctp-4"> <a href="javascript:void(0);"><img src={IMAGES.CASINO_888} alt="..." /></a>
                                <div class="ratin-Boxse rat-Bg-2">
                                    <ul>
                                        <li><a href="javascript:void(0);"><i class="fa fa-star"></i></a></li>
                                        <li><a href="javascript:void(0);"><i class="fa fa-star"></i></a></li>
                                        <li><a href="javascript:void(0);"><i class="fa fa-star"></i></a></li>
                                        <li><a href="javascript:void(0);"><i class="fa fa-star"></i></a></li>
                                        <li><a href="javascript:void(0);"><i class="fa fa-star"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="top-Listxt">
                                <div class="row align-items-center">
                                    <div class="col-lg-6 right-Marse">
                                        <div class="information-Boxse">
                                            <h3>888 casino erbjuder dig 100% bonus<br />
                                                        upp till 100 KR på din första insättning!</h3>
                                            <p>Ta del av 888 casino's generöra välkomstbonus på 100% upp<br />
                                                            till 100 KR, de har ett stort spelutbud och erbjuder självklart <br />
                                                                snabba och skattefria uttag via Trustly direkt till ditt bankkonto.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row align-items-center">
                                            <div class="col-lg-6 d-none d-lg-block">
                                                <div class="info-Boxse">
                                                    <ul>
                                                        <li>FA 100 KR I VALKOMSTBONUS</li>
                                                        <li>KOMPLETT SPELBOLAG</li>
                                                        <li>GRUNDADES 2003</li>
                                                        <li class="flg-icon">CASINOT HAR SVENSK SPELLICENS </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="review-Boxse"> <a href="javascript:void(0);" class="primary-Gbtn full-Width">FÅ 200 KR TILLBAKA <span>På din första insättning</span></a> </div>
                                                        <div class="list-Topse"><a className={classNames([ {'collapsed':paymentCollapsed},                    
                ])} onClick={()=>setPaymentCollapsed(!paymentCollapsed)}  data-toggle="collapse" data-target="#c-4">MER FAKTA<i class="fa fa-angle-up show_Ar"></i></a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="age-Breqsec">
                    <ul>
                        <li>18+</li>
                        <li><a href="#">Regler & villkor gäller</a></li>
                        <li>Spela ansvarsfullt</li>
                        <li><a href="#">www.stodlinjen.se</a></li>
                    </ul>
                </div>
                        </div>
                    </div>
                    </div>
                    
                    <div className={classNames([
                        "list-Bodyse",
                        {'collapse-Tab':paymentCollapsed},                    
                ])} id="c-4">
                    <div class="inner-Bpyse tp-Marse">
                        <div class="rdetail-Bhead">
                            <div class="row">
                                <div class="col-lg-4 align-items-center">
                                    <div class="reason-Btxtse">
                                        <h3><span><img src={IMAGES._1} alt="..." /></span> Regular promotions for new and returning players</h3>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="reason-Btxtse">
                                        <h3><span><img src={IMAGES._2} alt="..." /></span> Competitions and prize draws</h3>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="reason-Btxtse">
                                        <h3><span><img src={IMAGES._3} alt="..." /></span>Available to access internationally</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rdetail-Bodyse">
                            <div class="row">
                                <div class="col-lg-7">
                                    <div class="genric-Bwrapse">
                                        <div class="row align-items-center">
                                            <div class="col-5">
                                                <div class="genric-Binfose">
                                                    <p><span>Bonuskod:</span></p>
                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="genric-Binfose">
                                                    <p>Not Requierd </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="genric-Bwrapse">
                                        <div class="row align-items-center">
                                            <div class="col-5">
                                                <div class="genric-Binfose">
                                                    <p><span>Betalningsmetoder:</span></p>
                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="pyment-Boxse">
                                                    <ul>
                                                        <li><a href="#"><img src={IMAGES.VISA} alt="..." /></a></li>
                                                        <li><a href="#"><img src={IMAGES.PAYPAL} alt="..." /></a></li>
                                                        <li><a href="#"><img src={IMAGES.MASTER} alt="..." /></a></li>
                                                        <li><a href="#"><img src={IMAGES.PAYSAFE} alt="..." /></a></li>
                                                        <li><a href="#"><img src={IMAGES.NETELLER} alt="..." /></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="genric-Bwrapse">
                                        <div class="row align-items-center">
                                            <div class="col-5">
                                                <div class="genric-Binfose">
                                                    <p><span>Enheter:</span></p>
                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="app-Boxse">
                                                    <ul>
                                                        <li><a href="#"><i class="fa fa-globe"></i></a></li>
                                                        <li><a href="#"><i class="fa fa-apple"></i></a></li>
                                                        <li><a href="#"><i class="fa fa-android"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="spcae-Topse3 d-lg-none"> </div>
                                    <div class="genric-Bwrapse ryt-Grnse">
                                        <div class="row align-items-center">
                                            <div class="col-5 col-xl-12">
                                                <div class="genric-Binfose">
                                                    <p><span>Bonus<span class="d-lg-none">:</span></span></p>
                                                </div>
                                            </div>
                                            <div class="col-7 col-xl-12 ">
                                                <div class="spcae-Topse05"> </div>
                                                <div class="genric-Binfose">
                                                    <p> Up To 500 free spins!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="spcae-Topse3"> </div>
                                    <div class="review-Boxse"> <a href="javascript:void(0);" class="primary-Gbtn full-Width orange-Btn">LÄS RECENSION </a> </div>
                                    <div class="review-Boxse  d-lg-none">
                                        <div class="spcae-Topse4"> </div>
                                        <a href="javascript:void(0);" class="primary-Gbtn full-Width">FÅ 200 KR TILLBAKA <span>På din första insättning</span></a> </div>
                                </div>
                            </div>
                        </div>
                        <div class="age-Breqsec">
                            <ul>
                                <li>18+</li>
                                <li><a href="#">Regler & villkor gäller</a></li>
                                <li>Spela ansvarsfullt</li>
                                <li><a href="#">www.stodlinjen.se</a></li>
                            </ul>
                        </div>
                    </div>
                    </div>
            </div>
            </div>
            </Accordion>
    );
}
export default CasinoAccordionItem;
