import React,{useState,useEffect} from 'react';
import './styles.scss';
import LOGO_1 from '../../../assets/images/m-logo1.png';
import LOGO_2 from '../../../assets/images/m-logo2.png';
import LOGO_3 from '../../../assets/images/m-logo3.png';
import LOGO_4 from '../../../assets/images/m-logo4.png';
import LOGO_5 from '../../../assets/images/m-logo5.png';
import LOGO_6 from '../../../assets/images/m-logo6.png';
import LOGO_7 from '../../../assets/images/m-logo7.png';
import LOGO_8 from '../../../assets/images/m-logo8.png';
import CHECK_MONEY from '../../../assets/images/check-money.png';
import { TEXT_CONST } from '../../../shared';
import Slider from 'react-slick';
let data = [
    {
        name: 'Peter S',
        amount: 500,
        backgroundColor: '#294b99',
    },
    {
        name: 'Peter S',
        amount: 250,
        backgroundColor: '#000000',
    },
    {
        name: 'Peter S',
        amount: 450,
        backgroundColor: '#6c05a8',
    },
    {
        name: 'Peter S',
        amount: 240,
        backgroundColor: '#015536',
    }
]

let data1 = [
    {
        name: 'Peter S',
        amount: 500,
        backgroundColor: '#006d69',
    },
    {
        name: 'Peter S',
        amount: 250,
        backgroundColor: '#133154',
    },
    {
        name: 'Peter S',
        amount: 450,
        backgroundColor: '#202020',
    },
    {
        name: 'Peter S',
        amount: 240,
        backgroundColor: '#29b5fc',
    }
];

const settingsMoneyBackList = {
        dots: false,
        vertical:true,
        infinite: true,
        slidesToScroll: 1,
        verticalSwiping: false,
        slidesToShow: 4,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        speed:1500
};
    

const settingsPaidOutList = {
        dots: false,
        vertical:true,
        infinite: true,
        slidesToScroll: 1,
        verticalSwiping: false,
        slidesToShow: 4,
        arrows: false,
        autoplay:true,
        autoplaySpeed:6000,
        speed:1500
};
const LatestMembers = ({
    params,
}) => {
    const [autoPlayTime, setAutoPlayTime] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            console.log('here',autoPlayTime)
           setAutoPlayTime(true)
       },2500) 
    },[])

    return (
        <div class="money-Rmainse">
            <div class="title-Betse">
                <h2>Senaste medlemmarna som har fått <br />
            pengar tillbaka och utbetalt från Riskfritt.se</h2>
                <p> Vi på Riskfritt.se är väldigt transparenta och visar mer än gärna alla senaste medlemmar som har<br />
                fått sina verifkationer godkända.Samt visar medlemmar som har begärt en utbetalning och fått den inom <br />
                    några minuter till hens personliga bankkonto. Av säkerhetsskäl så kan vi endast visa förnamnet på personen i fråga.</p>
            </div>
            <div class="money-Rinnerse">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mny-Rtitle">
                            <h3>Senaste som fått pengar tillbaka</h3>
                        </div>
                        <div class="mony-Swrapse vertical-center2">
                             
                 <Slider {...settingsMoneyBackList}> 
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-4"> <a href="javascript:void(0);"><img src={LOGO_1} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>500 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-5"> <a href="javascript:void(0);"><img src={LOGO_2} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>400 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-7"> <a href="javascript:void(0);"><img src={LOGO_3} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>300 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-8"> <a href="javascript:void(0);"><img src={LOGO_4} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>300 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-3"> <a href="javascript:void(0);"><img src={LOGO_5} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>200 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-6"> <a href="javascript:void(0);"><img src={LOGO_6} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>100 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-2"> <a href="javascript:void(0);"><img src={LOGO_7} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>700 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-1"> <a href="javascript:void(0);"><img src={LOGO_8} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>1000 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </Slider>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mny-Rtitle topRpad">
                            <h3>Senaste som fått <br />
                  sina pengar utbetalda</h3>
                        </div>
                        <div class="mony-Swrapse vertical-center3">

                 <Slider {...settingsPaidOutList}> 
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-3"> <a href="javascript:void(0);"><img src={LOGO_5} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>1000 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-6"> <a href="javascript:void(0);"><img src={LOGO_6} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>700 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-2"> <a href="javascript:void(0);"><img src={LOGO_7} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>500 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-1"> <a href="javascript:void(0);"><img src={LOGO_8} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>600 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-4"> <a href="javascript:void(0);"><img src={LOGO_1} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>450 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-5"> <a href="javascript:void(0);"><img src={LOGO_2} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>300 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-7"> <a href="javascript:void(0);"><img src={LOGO_3} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>200 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="money-Listse">
                                <div class="row no-gutters  align-items-center">
                                    <div class="col-3">
                                        <div class="logo-Rmoneyse bg-mtp-8"> <a href="javascript:void(0);"><img src={LOGO_4} alt="..." /></a> </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row no-gutters  align-items-center">
                                            <div class="col-10">
                                                <div class="row no-gutters  align-items-center">
                                                    <div class="col-4">
                                                        <div class="client-Rdetailse">
                                                            <h3>Peter S</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="client-Rmoney">
                                                            <h3>100 SEK</h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="date-Rmoneyse">
                                                            <h3>2020.11.15</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="check-Rmoneyse"> <img src={CHECK_MONEY} alt="..." /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );}

export default LatestMembers;
