import React from 'react';
import './styles.scss';
import { ICONS, IMAGES, TEXT_CONST } from '../../../shared'
import { connect } from 'react-redux';

const Header = ({
    userToken,
}) => (<header>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg  p-0 main-Menuse"> <a href="#" class=" tog_nav"> <span class="cross"></span> </a>
            {!!userToken && <div class="collapse01 navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul>
                    <li class="nav-item " > <a class="nav-link" href="#"> <img src={IMAGES.NOTIFICATION} alt="..." /> <span class="not-Ficse m-Block">0</span> </a> </li>
                    <li class="nav-item "> <a class="nav-link" href="#">200<i>KR</i> <img src={IMAGES.WALLET} alt="..." /></a> </li>
                    <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" ><span class="d-none d-lg-flex">Välkommen Simon </span><img src={IMAGES.PROFILE} alt="..." /> <span class="fa fa-chevron-down"></span></a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown"> <a class="dropdown-item" href="#">Min profil</a> <a class="dropdown-item" href="#">Verifiering</a> <a class="dropdown-item" href="#">Uttag</a> <a class="dropdown-item" href="#">Historik</a> <a class="dropdown-item" href="#">Logga ut</a> </div>
                    </li>
                </ul>
            </div>}
        </nav>
    </div>
    <div class="left-Menuse">
        <div class="logo-Main"> <a href="#"><img src={IMAGES.LOGO} alt="logo" /></a></div>
        <div class="binny-Mosco"><img src={IMAGES.BAN} alt="..." /></div>
        <div class="left-Menunab">
            <ul>
                <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> OM OSS</a></li>
                <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> HUR FUNGERAR DET</a></li>
                <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> LIVE SUPPORT </a></li>
                <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> KONTAKTA OSS</a></li>
                <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> ANVÄNDARVILLKOR</a></li>
                <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> PRIVACY POLICY</a></li>
                <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> COOKIES</a></li>
            </ul>
        </div>
    </div>
    <div class="right-Sidebarse">
        <div class="off-nav"><a href="#"><span class="cross"></span></a></div>
        <div class="left-Menuse right-Menuse2">
            <div class="step-Wrapse">
                <div class="step-Topse">
                    <p>VERIFIERA OCH FÅ PENGAR TILLBAKA</p>
                </div>
                <div class="step-Secondse">
                    <p>För att vi ska kunna betala tillbaka summan du
              förlorade på din första insättning på ett valfritt casino från vår topplista så lär vi på Riskfritt.se först bekräfta att du är en ny kund på casinot och att du hade oturen att förlora din första insättning, vänligen följ stegen nedanför så får du en notifikation när vi har verifierat dina uppladdade dokument.</p>
                </div>
                <div class="input-Wrapse">
                    <div class="input-Grupse">
                        <label><span>1.</span> Vilket casino gjorde <br />du din första insättning på?</label>
                        <div class="casino-Selctmenu new-Msg">
                            <form>
                                <select id='mySelect'>
                                    <option value='0'>Välj casino</option>
                                    <option value='1'>William Hill</option>
                                    <option value='2'>MrGreen</option>
                                    <option value='3'>Lucky casino</option>
                                    <option value='4'>88 casino</option>
                                </select>
                            </form>
                            <ul class="nav nav-tabs" id="myTab">
                                <li class="active"><a href="#settings0"> </a></li>
                                <li><a href="#settings1"> <img src="assets/images/williom-hill.png" alt="..." /></a></li>
                                <li><a href="#settings2"><img src="assets/images/mr-green.png" alt="..." /></a></li>
                                <li><a href="#settings3"><img src="assets/images/lucky-casino.png" alt="..." /></a></li>
                                <li><a href="#settings4"><img src="assets/images/888-casino.png" alt="..." /></a></li>
                            </ul>
                        </div>
                        <div class="tab-content">
                            <div class="input-Grupse">
                                <label><span>2.</span> Ersättning du kan <br />
                                                få tillbaka fylls i automatiskt</label>
                            </div>
                            <div class="tab-pane active" id="settings0">
                                <div class="input-Grpsec">
                                    <p style={{ color: "#919191" }}>Välj casino och se hur mycket du kan få tillbaka</p>
                                </div>
                            </div>
                            <div class="tab-pane" id="settings1">
                                <div class="input-Grpsec">
                                    <p>500 SEK</p>
                                </div>
                            </div>
                            <div class="tab-pane" id="settings2">
                                <div class="input-Grpsec">
                                    <p>400 SEK</p>
                                </div>
                            </div>
                            <div class="tab-pane" id="settings3">
                                <div class="input-Grpsec">
                                    <p>300 SEK</p>
                                </div>
                            </div>
                            <div class="tab-pane" id="settings4">
                                <div class="input-Grpsec">
                                    <p>200 SEK</p>
                                </div>
                            </div>
                            <div class="tab-pane" id="settings5">
                                <div class="input-Grpsec">
                                    <p>100 SEK</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-Grupse">
                        <label><span>3.</span>Ladda upp en bild på<br />att du har gjort en insättning</label>
                        <p>Logga in på ditt konto på det utvalda casinot. Gå till dina transaktioner och ta en bild på att du har gjort en insättning.</p>
                        <div class="upld-Wrapse">
                            <div class="top-Uptxtse">
                                <div class="top-Uptxt">
                                    <h3>Dra filer hit eller <span>klicka här för att Bläddra</span></h3>
                                    <p>Vi accepterar endast .jpg eller .png filer.<br />
                      Filerna får inte vara större än 2 MB.</p>
                                </div>
                                <div class="fileupload fileupload-new new-Msg file-Upld" data-provides="fileupload">
                                    <div class=" btn-file neFs">
                                        <div class="fileupload-new neFs"></div>
                                        <input class="neFs" type="file" />
                                        <div class="row  no-gutters hd-Upse">
                                            <div class="col-8">
                                                <div class="left-Iupse">
                                                    <h3>Filnamn</h3>
                                                </div>
                                                <div class="left_Imgup">
                                                    <div class="casImg lftSe"> <img src="assets/images/casino-img.png" alt="..." /></div>
                                                    <div class="fileupload-preview"></div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="row no-gutters">
                                                    <div class="col-5">
                                                        <div class="left-Iupse">
                                                            <h3> Status </h3>
                                                        </div>
                                                        <div class="casImg"> <img src="assets/images/stats-img.png" alt="..." /></div>
                                                    </div>
                                                    <div class="col-7  topRdt">
                                                        <div class="left-Iupse">
                                                            <h3>Alternativ</h3>
                                                        </div>
                                                        <div class="row  topRdt1 no-gutters align-items-center">
                                                            <div class="col-6">
                                                                <div class="fileupload-exists p-upld">
                                                                    <input class="neFs2" type="file" />
                                                                    <i class="fa fa-pencil-square-o edit" aria-hidden="true"></i> </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="delSe"><a href="#" class="close fileupload-exists" data-dismiss="fileupload" style={{ float: "none" }}><i class="fa fa-trash" aria-hidden="true"></i> </a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-Grupse">
                    <label><span>4.</span> Ladda upp en bild på<br />
                                        att du har förlorat pengarna</label>
                    <p>Logga in på ditt konto på det utvalda casinot. Gå till dina transaktioner och ta en bild på att du har förlorat pengarna.</p>
                    <div class="upld-Wrapse">
                        <div class="top-Uptxtse">
                            <div class="top-Uptxt">
                                <h3>Dra filer hit eller <span>klicka här för att Bläddra</span></h3>
                                <p>Vi accepterar endast .jpg eller .png filer.<br />
                    Filerna får inte vara större än 2 MB.</p>
                            </div>
                            <div class="fileupload fileupload-new new-Msg file-Upld" data-provides="fileupload">
                                <div class=" btn-file neFs">
                                    <div class="fileupload-new neFs"></div>
                                    <input class="neFs" type="file" />
                                    <div class="row  no-gutters hd-Upse">
                                        <div class="col-8">
                                            <div class="left-Iupse">
                                                <h3>Filnamn</h3>
                                            </div>
                                            <div class="left_Imgup">
                                                <div class="casImg lftSe"> <img src="assets/images/casino-img.png" alt="..." /></div>
                                                <div class="fileupload-preview"></div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="row no-gutters">
                                                <div class="col-5">
                                                    <div class="left-Iupse">
                                                        <h3> Status </h3>
                                                    </div>
                                                    <div class="casImg"> <img src="assets/images/stats-img.png" alt="..." /></div>
                                                </div>
                                                <div class="col-7  topRdt">
                                                    <div class="left-Iupse">
                                                        <h3>Alternativ</h3>
                                                    </div>
                                                    <div class="row  topRdt1 no-gutters align-items-center">
                                                        <div class="col-6">
                                                            <div class="fileupload-exists p-upld">
                                                                <input class="neFs2" type="file" />
                                                                <i class="fa fa-pencil-square-o edit" aria-hidden="true"></i> </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="delSe"><a href="#" class="close fileupload-exists" data-dismiss="fileupload" style={{ float: "none" }}><i class="fa fa-trash" aria-hidden="true"></i> </a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-Grupse"> <a href="" class="primary-Gbtn full-Width gray-Btn"><i><img src="assets/images/bnk-id.png" alt="..." /></i>Verifiera och skicka in </a> </div>
            </div>
        </div>
        <div class="left-Menuse right-Menuse3 utanStp2">
            <div class="step-Wrapse">
                <div class="step-Topse">
                    <p>VERIFIERA OCH FÅ PENGAR TILLBAKA</p>
                </div>
                <div class="step-Secondse rht-Second2">
                    <h3>Verifiera med BankID för<br />
                                        att skicka in dokumenten</h3>
                    <p>Starta BankID-appen på din telefon och tryck<br />
                                            på ikonen för QR-kod i appen för att skanna<br />
                            QR-koden på den här sidan.</p>
                </div>
                <div class="bar-Codese"><img src="assets/images/barcode.png" alt="..." /></div>
                <div class="input-Grupse2"> <a href="#" id="stp3" class="primary-Gbtn full-Width dark-Green">Behöver du hjälp? </a> <a href="" class="primary-Gbtn full-Width dark-Red"> Abvryt</a> </div>
            </div>
        </div>
        <div class="left-Menuse sek-Popse right-Menuse3">
            <div class="step-Wrapse">
                <div class="step-Topse">
                    <p>VERIFIERA OCH FÅ PENGAR TILLBAKA</p>
                </div>
                <div class="step-Secondse pop-Step1">
                    <h3>Du har<span> 200 KR</span> i ditt<br />
                                                    saldo på Riskfritt.se! </h3>
                    <p>Fyll i summan här nedanför som du vill ta ut till ditt privata bankkonto. Överföring sker snabbt och säkert med betalningsmetoden Brite, som använder sig av BankID för att göra säkra utbetalningar direkt till ditt bankkonto på bara några minuter!</p>
                </div>
                <div class="sek-Wrapse">
                    <select class="list-group custom-select new-Msg" >
                        <option >500 SEK</option>
                        <option >400 SEK</option>
                        <option >300 SEK</option>
                        <option >200 SEK</option>
                    </select>
                </div>
                <div class="utang-Popse"> <a href="#" class="primary-Gbtn full-Width dark-Green"><i><img src="assets/images/bnk-id.png" alt="..." /></i>BEKRÄFTA UTTAG</a></div>
                <div class="brite-Lgsec"><img src="assets/images/brite2.svg" alt="..." /></div>
            </div>
        </div>
        <div class="left-Menuse right-Menuse4 utan-Stp3">
            <div class="step-Wrapse">
                <div class="step-Topse">
                    <h3>Skanna QR-koden </h3>
                </div>
                <div class="img-Bkd"><img src="assets/images/bkd-img.jpg" alt="..." /></div>
                <ul>
                    <li>Lorem Ipsum is simply text</li>
                    <li>Lorem Ipsum has been the industry's</li>
                    <li>standard dummy text ever since</li>
                    <li>Lorem Ipsum is simply dummy text </li>
                </ul>
                <div class="input-Grupse2"> <a href="#" id="stp4" class="primary-Gbtn full-Width dark-Green">STÄNG </a> </div>
            </div>
        </div>
        <div class="left-Menuse right-Menuse4 utan-Stp4">
            <div class="step-Wrapse">
                <div class="step-Topse">
                    <p>VERIFIERA OCH FÅ PENGAR TILLBAKA</p>
                </div>
                <div class="step-Secondse rht-Second2">
                    <h3>Tack för att du skickade<br />
                                                        in dokumenten för verifiering!</h3>
                    <p>När vi är klar med verifieringen och den har blivit godkänd får du en notifikation här på sidan och din kontobalans på Riskfritt.se fylls på automatiskt.</p>
                </div>
                <div class="step-Secondse rht-Second2">
                    <h3>Verifiera med BankID för <br />
                                                            att skicka in dokumenten</h3>
                    <p>För att vi ska kunna betala tillbaka summan du
              förlorade på din första insättning på ett valfritt casino från vår topplista så lär vi på Riskfritt.se först bekräfta att du är en.</p>
                </div>
                <div class="binny-Mosco"><img src="assets/images/ban-img.png" alt="..." /></div>
                <div class="input-Grupse2"> <a href="" class="primary-Gbtn full-Width dark-Green">STÄNG </a> </div>
            </div>
        </div>
        <div class="left-Menuse right-Menuse5">
            <div class="step-Wrapse">
                <div class="step-Topse">
                    <p>TA UT DINA PENGAR ENKELT, SNABBT OCH SÄKERT!</p>
                </div>
                <div class="step-Secondse rht-Second2">
                    <h3>BEKRÄFTA DITT <br />
                                                                UTTAG MED BANKID</h3>
                    <p>Starta BankID-appen på din telefon och tryck<br />
                                                                    på ikonen för QR-kod i appen för att skanna<br />
                                                                        QR-koden på den här sidan.</p>
                </div>
                <div class="bar-Codese"><img src="assets/images/barcode.png" alt="..." /></div>
                <div class="input-Grupse3">
                    <ul>
                        <li><a href="#">Abvryt </a></li>
                        <li> <a href="#">Behover du hjelp? </a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
    );

const mapStateToProps = state => {
    return {
        userToken: state.common.userToken
    }
}

export default connect(mapStateToProps)(Header);
