// import FOOTERSTRIP from '../../assets/img/footer_strip.png'
// import LOGO from "../../assets/img/logo.png";
// import BRITELOGO from '../../assets/img/logos/brite_logo.png'
// import MASCOTPIC from "../../assets/img/front/mascot_pic.png";
// import MASTER from "../../assets/img/front/master.png";
// import TRUSTPILOTLOGO from "../../assets/img/front/Trustpilot_logo.png";
// import TRUSTPILOTRATING from "../../assets/img/front/trustpilot_rating.png";
// import E8EEN_PLUS from "../../assets/img/logos/18+.png";
// import SP from "../../assets/img/logos/sp_logo.png";
// import STOD from "../../assets/img/logos/stodlinjen.png";

import FOOTER_LAYER from '../../assets/images/fot-layer.svg';
import CAT_1 from '../../assets/images/cat-1.svg';
import CAT_2 from '../../assets/images/cat-2.svg';
import CAT_3 from '../../assets/images/cat-3.svg';
import CAT_4 from '../../assets/images/cat-4.svg';
import CAT_5 from '../../assets/images/cat-5.svg';
import CAT_6 from '../../assets/images/cat-6.svg';
import CAT_7 from '../../assets/images/cat-7.svg';
import WHT_CAT_1 from '../../assets/images/wht-cat-1.svg';
import WHT_CAT_2 from '../../assets/images/wht-cat-2.svg';
import WHT_CAT_3 from '../../assets/images/wht-cat-3.svg';
import WHT_CAT_4 from '../../assets/images/wht-cat-4.svg';
import WHT_CAT_5 from '../../assets/images/wht-cat-5.svg';
import WHT_CAT_6 from '../../assets/images/wht-cat-6.svg';
import WHT_CAT_7 from '../../assets/images/wht-cat-7.svg';
import CASINO_888 from '../../assets/images/888-casino.png';
import _1 from '../../assets/images/1.svg';
import _2 from '../../assets/images/2.svg';
import _3 from '../../assets/images/3.svg';
import VISA from '../../assets/images/visa.png';
import PAYPAL from '../../assets/images/paypal.png';
import MASTER from '../../assets/images/master.png';
import PAYSAFE from '../../assets/images/paysafe.png';
import NETELLER from '../../assets/images/neteller.png';
import LOGO_2 from '../../assets/images/logo2.png';
import FT_LOGO_1 from '../../assets/images/ft-logo1.png';
import BRITE from '../../assets/images/brite.svg';
import FT_ICON from '../../assets/images/ft-icon.png';
import BOT_IMG_1 from '../../assets/images/bot-img1.png';
import NOTIFICATION from '../../assets/images/noti-fi.svg';
import WALLET from '../../assets/images/walet-2.svg';
import PROFILE from '../../assets/images/profile.svg';
import LOGO from '../../assets/images/logo.png';
import BAN from '../../assets/images/ban-img.png';
import BANK_ID from '../../assets/images/bnk-id.png';
import BANK_ID_SVG from '../../assets/images/bank-id.svg';
import H_CURVE from '../../assets/images/curve-shape.png';
import STAR from '../../assets/images/star.png';
import TRUST_LOGO from '../../assets/images/trustlogo.png';
import SPIN_CAS from '../../assets/images/spin-cas.svg';
import DOC_SEARCH from '../../assets/images/doc-serch.svg';
import WALLET_SVG from '../../assets/images/walet.svg';


export const IMAGES = {
    CAT_1: CAT_1,
    TRUST_LOGO: TRUST_LOGO,
    CAT_2: CAT_2,
    BANK_ID_SVG: BANK_ID_SVG,
    SPIN_CAS: SPIN_CAS,
    DOC_SEARCH: DOC_SEARCH,
    WALLET_SVG: WALLET_SVG,
    STAR: STAR,
    BAN: BAN,
    BANK_ID: BANK_ID,
    LOGO: LOGO,
    H_CURVE: H_CURVE,
    PROFILE: PROFILE,
    NOTIFICATION: NOTIFICATION,
    WALLET: WALLET,
    FT_ICON: FT_ICON,
    BOT_IMG_1: BOT_IMG_1,
    CAT_3: CAT_3,
    FT_LOGO_1: FT_LOGO_1,
    BRITE: BRITE,
    CAT_4: CAT_4,
    LOGO_2: LOGO_2,
    CAT_5: CAT_5,
    CAT_6: CAT_6,
    FOOTER_LAYER: FOOTER_LAYER,
    CAT_7: CAT_7,
    WHT_CAT_1: WHT_CAT_1,
    WHT_CAT_2: WHT_CAT_2,
    WHT_CAT_3: WHT_CAT_3,
    WHT_CAT_4: WHT_CAT_4,
    WHT_CAT_5: WHT_CAT_5,
    WHT_CAT_6: WHT_CAT_6,
    WHT_CAT_7: WHT_CAT_7,
    CASINO_888: CASINO_888,
    _1: _1,
    _2: _2,
    _3: _3,
    VISA: VISA,
    PAYPAL: PAYPAL,
    MASTER: MASTER,
    PAYSAFE: PAYSAFE,
    NETELLER: NETELLER,
    // FOOTER_STRIP:FOOTERSTRIP,
    // LOGO: LOGO,
    // BRITE_LOGO:BRITELOGO,
    // MASCOT: MASCOTPIC,
    // MASTER: MASTER,
    // TRUST_PILOT_LOGO: TRUSTPILOTLOGO,
    // TRUST_PILOT_RATING: TRUSTPILOTRATING,
    // E8EEN_PLUS: E8EEN_PLUS,
    // SP: SP,
    // STOD:STOD,
}