import React, { useState } from 'react';
import './styles.css';
import { ICONS, IMAGES, TEXT_CONST, ILLUSTRATIONS, ROUTE_CONSTANTS } from '../../../shared'
import { CustomButton, LoginModal, SignUpModal } from '../../atoms';
import { connect } from 'react-redux';
import { getRedirectUrl } from '../../../redux/actions';

const HomeBanner = ({
  userToken,
  getRedirectUrl
}) => {
  const [showLoginModal, toggleLoginModal] = useState(false);
  const [showSignupModal, toggleSignupModal] = useState(false);

  return (
    <div class="banner-Mainse">
      <LoginModal
        show={showLoginModal}
        onClose={() => toggleLoginModal(false)}
        onOpenSignup={() => { toggleLoginModal(false); toggleSignupModal(true) }}
      />
      <SignUpModal
        show={showSignupModal}
        onClose={() => toggleSignupModal(false)}
        getRedirectUrl={(success, failure) => {
          console.log('here calling',success, failure)
          getRedirectUrl(success, failure)
        }}
        onOpenLogin={() => { toggleSignupModal(false); toggleLoginModal(true) }}
      />
      <div class="container">
        <div class="inner-Banse">
          <div class="logo-Main"> <a href="#"><img src={IMAGES.LOGO} alt="logo" /></a></div>
          <div class="ban-Txtwrap">
            <h1>FÅ TILLBAKA UPP TILL 500 SEK<br />
            PÅ DIN FÖRSTA INSÄTTNING</h1>
            {userToken ? <h4>Välkommen till Riskfritt.se, {!!userToken && <span>Simon!</span>} Du kan nu jämföra och läsa recensioner <br />
            om alla nya och bästa casinon online som innehar en svensk casino licens.</h4>
              : <h4>Välkommen till Riskfritt.se, Du kan nu jämföra och läsa <br />recensioner om alla nya och bästa casinon online som innehar en svensk casino licens.</h4>
            }
            <p> Att spela och vinna på ett casino handlar oftast om ren tur och alla har inte turen att vinna. Därför erbjuder vi<br />
            alla våra medlemmar som gör sin första insättning på ett casino att få tillbaka upp till 500 KR från oss, vilket <br />
            ger dig möjligheten att testa flera olika casinon och ändå få dina pengar tillbaka om oturen slår till. </p>
            {userToken ?
              <div class="play-Btnse">
                <a
                  onClick={() => toggleLoginModal(true)}
                  class="primary-Gbtn small-Btn dark-Green pop-Clcik"><img src={IMAGES.BANK_ID} alt="..." />DU HAR: 200 KR <span>Ta ut dina pengar här</span> </a>
                <a
                  onClick={() => toggleSignupModal(true)}
                  class="primary-Gbtn small-Btn dark-Green pop-Clcik"><img src={IMAGES.BANK_ID} alt="..." />VERIFIERING<span>Verifiera & få pengar tillbaka</span> </a> </div>
              :
              <div class="play-Btnse">
                <a
                  onClick={() => toggleLoginModal(true)}
                  class="primary-Gbtn small-Btn dark-Green pop-Clcik"><img src={IMAGES.BANK_ID} alt="..." />LOGGA IN <span>Logga in med BankID</span> </a>
                <a
                  onClick={() => toggleSignupModal(true)}
                  class="primary-Gbtn small-Btn dark-Green pop-Clcik"><img src={IMAGES.BANK_ID} alt="..." />REGISTRERA DIG<span>Enkel registrering med BankID</span> </a> </div>
            }
          </div >
          <div class="ratingSec">
            <div class="row no-gutters align-items-center">
              <div class="col-lg-4">
                <div class="rat-Leftse">
                  <h3>Se våra 23 omdömen</h3>
                </div>
                <div class="spcae-Topse3 d-lg-none"> </div>
              </div>
              <div class="col-lg-5">
                <div class="rat-Midse">
                  <ul>
                    <li><span><img src={IMAGES.STAR} alt="..." /></span></li>
                    <li><span><img src={IMAGES.STAR} alt="..." /></span></li>
                    <li><span><img src={IMAGES.STAR} alt="..." /></span></li>
                    <li><span><img src={IMAGES.STAR} alt="..." /></span></li>
                    <li><span class="halfBg"><img src={IMAGES.STAR} alt="..." /></span></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="spcae-Topse3 d-lg-none"> </div>
                <div class="rat-Leftse"> <img src={IMAGES.TRUST_LOGO} alt="..." /></div>
              </div>
            </div>
          </div >
          <div class="ban-Botse"> <img src={IMAGES.BAN} alt="..." /></div>
        </div >
      </div >
      <div class="bottom-Layse"><img src={IMAGES.H_CURVE} alt="..." /></div>
    </div >
  );
}

const mapStateToProps = state => {
  return {
    userToken: state.common.userToken
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRedirectUrl:(success,failure)=>dispatch(getRedirectUrl(success,failure))
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(HomeBanner);
