import React from 'react';
import './styles.scss';
import { ICONS, IMAGES, TEXT_CONST,REGISTER_STEPS } from '../../../shared'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import Slider from 'react-slick';

const settings = {
    dots: true,

    infinite: false,
  
    speed: 1000,
  
    slidesToShow: 4,
  
    slidesToScroll: 1,
  
     autoplay: false,
     arrows:false,
  
    autoplaySpeed: false,
  
    responsive: [
  
      {
  
        breakpoint: 1200,
  
        settings: {
  
          slidesToShow: 3,
  
        }
  
      },
  
      {
  
        breakpoint:991,
  
        settings: {
  
          slidesToShow: 2, 
  
        }
  
      },
  
       {
  
        breakpoint:767,
  
        settings: {
  
          slidesToShow: 2, 
  
        }
  
      },
       
  
      {
  
        breakpoint: 420,
  
        settings: {
  
          slidesToShow: 1, 
  
        }
  
      }
  
      // You can unslick at a given breakpoint now by adding:
  
      // settings: "unslick"
  
      // instead of a settings object
  
    ]
    };
    
const HowItWorks = ({
    params,
}) => (
        <div class="working-Mainse">
            <div class="title-Betse">
                <h2>Hur fungerar det?</h2>
            </div>
            <div class="inner-Pwayse">
                <div class="row center"> 
                <div class="col-lg-12"> 
                 <Slider {...settings}> 
                        {REGISTER_STEPS.map((item, index) => (<div class="slideWrapse">
                            <div class="play-Rmain"><span class="countSe">{`${index+1}.`}</span>
                            <div class="ply-Iconse"><img src={item.cardIcon} alt="..." /></div>
                            <div class="ply-Txtsec">
                                    <h3>{item.heading}</h3>
                                    <p>{item.description}</p>
                            </div>
                        </div>
                        </div>))}  
                    </Slider>   </div>   
                </div>
            </div>
        </div>
    );

export default HowItWorks;
