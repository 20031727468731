import React from 'react';
import {
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
const { ROUTE_CONSTANTS } = require(`../../shared`);
const { HomeScreen } = require(`../../views/1.0`);

const AuthRouter = (props) => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ROUTE_CONSTANTS.ROOT} component={HomeScreen} />
                <Redirect to={ROUTE_CONSTANTS.ROOT} />
            </Switch>
        </React.Fragment>
    );
}

export default AuthRouter;