import React from 'react';
import { CasinoAccordionList, Footer, Header, HomeBanner, HowItWorks, LatestMembers, NewsList, QA_Accordion } from '../../../../components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeScreen = ({
    params,
}) => (
        <React.Fragment >
            <Header />
            <HomeBanner />
            <div class="mid-Mainsec">
                <div class="container">
                    <HowItWorks />
                    <CasinoAccordionList />
                    <NewsList />
                    <LatestMembers />
                    <QA_Accordion />
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );

export default HomeScreen;
