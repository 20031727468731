import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { IMAGES, TEXT_CONST } from '../../../shared';
import { CasinoAccordionItem } from '../../molecules';
import Slider from 'react-slick';
import './styles.scss';

const settings = {
    dots: true,

    infinite: false,
  
    speed: 1000,
  
    slidesToShow: 8,
  
    slidesToScroll: 1,
  
     autoplay: false,
     arrows:false,
  
    autoplaySpeed: false,
  
    responsive: [
  
      {
  
        breakpoint: 1200,
  
        settings: {
  
          slidesToShow: 6,
  
        }
  
      },
  
      {
  
        breakpoint:991,
  
        settings: {
  
          slidesToShow: 5, 
  
        }
  
      },
  
       {
  
        breakpoint:767,
  
        settings: {
  
          slidesToShow: 4, 
  
        }
  
      },
  
      {
  
        breakpoint: 485,
  
        settings: {
  
          slidesToShow: 3, 
  
        }
  
      }
  
      // You can unslick at a given breakpoint now by adding:
  
      // settings: "unslick"
  
      // instead of a settings object
  
    ]
  
};
    
let DATA = [
    {
        label: 'Populära',
        id: 0,
        inactive: IMAGES.CAT_1,
        active_icon: IMAGES.WHT_CAT_1
    },
    {
        label: 'Nya casinon',
        id: 1,
        inactive: IMAGES.CAT_2,
        active_icon: IMAGES.WHT_CAT_2
    },
    {
        label: 'Utan konto',
        id: 2,
        inactive: IMAGES.CAT_3,
        active_icon: IMAGES.WHT_CAT_3
    },
    {
        label: 'Swish casinon',
        id: 3,
        inactive: IMAGES.CAT_4,
        active_icon: IMAGES.WHT_CAT_4
    },
    {
        label: 'Bäst bonus',
        id: 4,
        inactive: IMAGES.CAT_5,
        active_icon: IMAGES.WHT_CAT_5
    },
    {
        label: 'Freespins',
        id: 5,
        inactive: IMAGES.CAT_6,
        active_icon: IMAGES.WHT_CAT_6
    },
    {
        label: 'Betting',
        id: 6,
        inactive: IMAGES.CAT_7,
        active_icon: IMAGES.WHT_CAT_7
    }
]

const CasinoAccordionList = ({

}) => {
    const [active, updateActive] = useState(0);
    const updateData = (id, index) => {
        let dataClone = DATA;
        let selectedDataClone = dataClone.splice(index,1);
        DATA = [...selectedDataClone, ...dataClone];
        updateActive(id);
    }
    return (
        <div class="toplist-Wrap">
            <div class="title-Betse">
                <h2>Jämför, välj och börja spela!</h2>
                <p>Välj det casino som passar dig bäst, kolla vad de har att erbjuda, läs recensionerna och kolla hur mycket pengar du kan få tillbaka på din första insättning om du skulle ha oturen att förlora dem.<span> Det är viktigt att du har registrerat ett konto med hjälp av BankID hos oss på Riskfritt.se innan du börjar spela, annars kan du inte få tillbaka några pengar från oss om du skulle förlora pengarna du gjorde på din första insättning.</span> På den gröna knappen kan du se hur mycket du kan få tillbaka på din första insättning, och detta erbjudande gäller endast på din första insättning och om du är en ny kund hos casinot du väljer att spela på.</p>
            </div>
            <div class="tab-Rmainse">
                <div class="tab-Rcatese">
                    <ul class="nav nav-tabs2 center3" id="myTab3" role="tablist">                          
                 <Slider {...settings}> 
                        {DATA.map((item, index) => {
                            let { label, id, active_icon, inactive } = item;
                            return (<li key={index} class="nav-item" role="presentation">
                                <a style={{ cursor: 'pointer' }} onClick={() => updateData(id,index)} class={`nav-link ${active == id && 'active'}`} data-toggle="tab" >
                                    <span>
                                        <img class="hover-Out" src={inactive}
                                            alt="..." />
                                        <img class="hover" src={active_icon} alt="..." />
                                    </span>
                                    {label}
                                </a>
                            </li>)
                        })}
                    </Slider>  
                        {/* <li style={{ display: "none" }}></li>
                        <li class="nav-item ic-1" role="presentation"> <a class="nav-link active" id="a1" data-toggle="tab" href="#a"><span><img class="hover-Out" src={IMAGES.CAT_1} alt="..." /><img class="hover" src={IMAGES.WHT_CAT_1} alt="..." /></span> Populära</a> </li>
                        <li class="nav-item ic-2" role="presentation"> <a class="nav-link" id="a2" data-toggle="tab" href="#b"><span><img class="hover-Out" src={IMAGES.CAT_2} alt="..." /><img class="hover" src={IMAGES.WHT_CAT_2} alt="..." /></span>Nya casinon</a> </li>
                        <li class="nav-item ic-3" role="presentation"> <a class="nav-link" id="a3" data-toggle="tab" href="#c"><span><img class="hover-Out" src={IMAGES.CAT_3} alt="..." /><img class="hover" src={IMAGES.WHT_CAT_3} alt="..." /></span>Utan konto</a> </li>
                        <li class="nav-item ic-4" role="presentation"> <a class="nav-link " id="a4" data-toggle="tab" href="#d"><span><img class="hover-Out" src={IMAGES.CAT_4} alt="..." /><img class="hover" src={IMAGES.WHT_CAT_4} alt="..." /></span>Swish casinon</a> </li>
                        <li class="nav-item ic-5" role="presentation"> <a class="nav-link" id="a5" data-toggle="tab" href="#e"><span><img class="hover-Out" src={IMAGES.CAT_5} alt="..." /><img class="hover" src={IMAGES.WHT_CAT_5} alt="..." /></span>Bäst bonus</a> </li>
                        <li class="nav-item ic-6" role="presentation"> <a class="nav-link" id="a6" data-toggle="tab" href="#f"><span><img class="hover-Out" src={IMAGES.CAT_6} alt="..." /><img class="hover" src={IMAGES.WHT_CAT_6} alt="..." /></span>Freespins</a> </li>
                        <li class="nav-item ic-7" role="presentation"> <a class="nav-link " id="a7" data-toggle="tab" href="#g"><span><img class="hover-Out" src={IMAGES.CAT_7} alt="..." /><img class="hover" src={IMAGES.WHT_CAT_7} alt="..." /></span>Betting</a> </li> */}
                    </ul>
                </div>
            </div>
            <div class="tab-content tp-Rcontse" id="myTabContent">
                <div class="tab-pane fade show active" id="a" >
                    <div class="toplist-Sec">
                        {[1, 2, 3, 4].map(item => <CasinoAccordionItem />)}
                        <div class="load-Morese"><a href="javascript:void(0);" class="primary-Gbtn large-Btn">LADDA FLER CASINON</a> </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CasinoAccordionList;
