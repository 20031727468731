import { IMAGES } from './images';
export const EMAIL_REGX = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGX = /^[A-Z.a-z'\b ]+$/;

export const REGISTER_STEPS = [
    {
        cardIcon: IMAGES.BANK_ID_SVG,
        heading: 'Registrera dig med BankID',
        description: 'Det är enkelt och gratis att skapa ett konto hos oss, klicka på Registrera dig-knappen, fyll i namn och epost så verifierar du dig själv med BankID efter du har gjort det så är du en registrerad medlem hos oss!'
    },
    {
        cardIcon: IMAGES.SPIN_CAS,
        heading: 'Välj ett casino att spela på',
        description: 'I topplistan nedan har vi tagit fram både nya och de bästa casinon som innehar en svensk licens. Läs och välj ett casino som du inte har spelat på tidigare, klicka på gröna knappen och gör din första insättning på casinot.'
    },
    {
        cardIcon: IMAGES.DOC_SEARCH,
        heading: 'Hade du otur att förlora pengarna?',
        description: 'Ingen fara, vi är här för att hjälpa!  Gå till menyn Verifiering. Där kan du skicka in en bild på att du har gjort en insättning på casinot och förlorat det. Vi granskar det och betalar sedan tillbaka summan som du förlorade!'
    },
    {
        cardIcon: IMAGES.WALLET_SVG,
        heading: 'Automatiska utbetalningar',
        description: 'När granskningen har godkänds så uppdateras din kontobalans automatiskt. Där kan du sedan välja att göra ett uttag. Verifiera dig med BankID och pengarna är tillbaka på ditt personliga bankkonto på några minuter.'
    },
];

export const STATUS_CODE = {
    unAuthorized: 401,
    successful:200
}