import { REHYDRATE } from "redux-persist";

const initialCommonState = {
    userToken: '',
};

const CommonReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {
        case REHYDRATE:
            let common = ((action || {}).payload || {}).CommonReducer || initialCommonState
            // common.userToken && updateAuthToken(common.userToken || '');
            return {
                ...state,
                userToken: common.userToken,
                // ...(action.payload || {}).common
            };
        default:
            return state;
    }
};

export default CommonReducer