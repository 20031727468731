import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import SecuredRoutes from './secured-routes';
import PublicRoutes from './public-routes';

const RootRouter = ({
    access_token
}) => {
    return (
        <BrowserRouter>
            {
                access_token ? <SecuredRoutes />
                    : <PublicRoutes />
            }
        </BrowserRouter>
    )
}


const mapStateToProps = state => {
    return {
        access_token: '564564'
    };
};

export default connect(mapStateToProps)(RootRouter);
