import { takeLatest, all, put } from "redux-saga/effects";
import { postRequestNoAuth } from '../../helpers';
import { STATUS_CODE } from '../../shared';
import { GET_REDIRECT_URL } from '../actions';

const BANKID_API_KEY = process.env.REACT_APP_BANKID_API_KEY;
const BANKID_AUTH_SERVICE_KEY = process.env.REACT_APP_BANKID_AUTH_SERVICE_KEY;
function* getRedirectingUrl({ success, failure }) {
    console.log('hi')
    try {
        const response = yield postRequestNoAuth({
            API: `https://client.grandid.com/json1.1/FederatedLogin?apiKey=${BANKID_API_KEY}&authenticateServiceKey=${BANKID_AUTH_SERVICE_KEY}`
            // HEADER: {
            //     'Api-Token':
            // "Content-Type":'application/json, charset=utf8'
            // }
        });
        console.log('saga response',response)
        if (response.status === STATUS_CODE.unAuthorized) {
            failure(response)
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response);
        }
        else {
            success(response);
        }
    }
    catch (error) {
    }
};

function* AuthSaga() {
    yield all([
        takeLatest(GET_REDIRECT_URL,getRedirectingUrl)
    ]);
}

export default AuthSaga;
