import React, { useState } from 'react';
import './styles.scss';
import { Accordion, Container, Row, Col, Card } from 'react-bootstrap'
import { ICONS, TEXT_CONST } from '../../../shared';
import classNames from 'classnames';

let DATA = [
    {
        question: 'What is Lorem Ipsum?',
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        question: 'What is Lorem Ipsum?',
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        question: 'What is Lorem Ipsum?',
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        question: 'What is Lorem Ipsum?',
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        question: 'What is Lorem Ipsum?',
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        question: 'What is Lorem Ipsum?',
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        question: 'What is Lorem Ipsum?',
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        question: 'What is Lorem Ipsum?',
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }
]
const QA_Accordion = ({
    data = DATA,
}) => {
    const [activeAccordion, updateActiveAccordion] = useState('');
    const [openedQuestion, setOpenedQuestion] = useState(new Set());
    return (
        <div class="play-Boxse">
            <div class="title-Betse">
                <h2>Frågor och svar</h2>
                <p>Här under har vi listat de vanligaste frågorna som personer har undrat, och hoppas du kan hitta det svaret du letar efter också.<br />
                    Om inte så kan du alltid kontakta vår live support genom att klicka på den runda ikonen till höger, så hjälper vi dig så hjälper vid dig!</p>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="play-Boxtxt">
                        <div onClick={() => {
                            let questionsClone = new Set(openedQuestion);
                            if (questionsClone.has('b-1')) {
                                console.log('here')
                                questionsClone.delete('b-1');
                            }
                            else {
                                console.log('else here')
                                questionsClone.add('b-1');
                            }
                            setOpenedQuestion(questionsClone);
                        }} class="play-Header">
                            <h3 className={classNames([                                
                                {'collapsed':!openedQuestion.has('b-1')}
                            ])} data-toggle="collapse" data-target="#b-1"><span>✅</span>Är Riskfritt.se <br /> säkert att använda sig av? </h3>
                        </div>
                        <div className={classNames([
                            "play-Bodyse",
                            {'collapse-Tab':!openedQuestion.has('b-1')}
                        ])} id="b-1">
                            <div class="play-Bodytxt acc-Bottomse">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature </p>
                            </div>
                        </div>
                    </div>
                    <div class="play-Boxtxt">
                        <div onClick={() => {
                            let questionsClone = new Set(openedQuestion);
                            if (questionsClone.has('b-2')) {
                                questionsClone.delete('b-2');
                            }
                            else {
                                questionsClone.add('b-2');
                            }
                            setOpenedQuestion(questionsClone);
                        }}  class="play-Header">
                            <h3 className={classNames([                                
                                {'collapsed':!openedQuestion.has('b-2')}
                            ])} data-toggle="collapse" data-target="#b-2"><span> ✅ </span> Hur mycket pengar<br />  kan man få tillbaka?</h3>
                        </div>
                        <div className={classNames([
                            "play-Bodyse",
                            {'collapse-Tab':!openedQuestion.has('b-2')}
                        ])} id="b-2">
                            <div class="play-Bodytxt acc-Bottomse">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature </p>
                            </div>
                        </div>
                    </div>
                    <div class="play-Boxtxt">
                        <div onClick={() => {
                            let questionsClone = new Set(openedQuestion);
                            if (questionsClone.has('b-3')) {
                                questionsClone.delete('b-3');
                            }
                            else {
                                questionsClone.add('b-3');
                            }
                            setOpenedQuestion(questionsClone);
                        }}  class="play-Header">
                            <h3 className={classNames([                                
                                {'collapsed':!openedQuestion.has('b-3')}
                            ])} data-toggle="collapse" data-target="#b-3"><span> ✅ </span> Varför betalar Riskfritt.se <br /> tillbaka förlorade pengar?</h3>
                        </div>
                        <div className={classNames([
                            "play-Bodyse",
                            {'collapse-Tab':!openedQuestion.has('b-3')}
                        ])} id="b-3">
                            <div class="play-Bodytxt acc-Bottomse">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature </p>
                            </div>
                        </div>
                    </div>
                    <div class="play-Boxtxt">
                        <div onClick={() => {
                            let questionsClone = new Set(openedQuestion);
                            if (questionsClone.has('b-4')) {
                                questionsClone.delete('b-4');
                            }
                            else {
                                questionsClone.add('b-4');
                            }
                            setOpenedQuestion(questionsClone);
                        }}  class="play-Header">
                            <h3 className={classNames([                                
                                {'collapsed':!openedQuestion.has('b-4')}
                            ])} data-toggle="collapse" data-target="#b-4"><span> ✅ </span> Hur lång tid tar <br /> det att få tillbaka pengar? </h3>
                        </div>
                        <div className={classNames([
                            "play-Bodyse",
                            {'collapse-Tab':!openedQuestion.has('b-4')}
                        ])} id="b-4">
                            <div class="play-Bodytxt acc-Bottomse">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="play-Boxtxt">
                        <div onClick={() => {
                            let questionsClone = new Set(openedQuestion);
                            if (questionsClone.has('b-b1')) {
                                questionsClone.delete('b-b1');
                            }
                            else {
                                questionsClone.add('b-b1');
                            }
                            setOpenedQuestion(questionsClone);
                        }}  class="play-Header">
                            <h3 className={classNames([                                
                                {'collapsed':!openedQuestion.has('b-b1')}
                            ])} data-toggle="collapse" data-target="#b-b1"><span> ✅ </span> Hur fungerar Riskfritt.se?</h3>
                        </div>
                        <div className={classNames([
                            "play-Bodyse",
                            {'collapse-Tab':!openedQuestion.has('b-b1')}
                        ])} id="b-b1">
                            <div class="play-Bodytxt acc-Bottomse">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature </p>
                            </div>
                        </div>
                    </div>
                    <div class="play-Boxtxt">
                        <div onClick={() => {
                            let questionsClone = new Set(openedQuestion);
                            if (questionsClone.has('b-b2')) {
                                questionsClone.delete('b-b2');
                            }
                            else {
                                questionsClone.add('b-b2');
                            }
                            setOpenedQuestion(questionsClone);
                        }}  class="play-Header">
                            <h3 className={classNames([                                
                                {'collapsed':!openedQuestion.has('b-b2')}
                            ])} data-toggle="collapse" data-target="#b-b2"><span> ✅ </span> Har alla casinon <br /> svensk casino licens?</h3>
                        </div>
                        <div className={classNames([
                            "play-Bodyse",
                            {'collapse-Tab':!openedQuestion.has('b-b2')}
                        ])} id="b-b2">
                            <div class="play-Bodytxt acc-Bottomse">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature </p>
                            </div>
                        </div>
                    </div>
                    <div class="play-Boxtxt">
                        <div onClick={() => {
                            let questionsClone = new Set(openedQuestion);
                            if (questionsClone.has('b-b3')) {
                                questionsClone.delete('b-b3');
                            }
                            else {
                                questionsClone.add('b-b3');
                            }
                            setOpenedQuestion(questionsClone);
                        }}  class="play-Header">
                            <h3 className={classNames([                                
                                {'collapsed':!openedQuestion.has('b-b3')}
                            ])} data-toggle="collapse" data-target="#b-b3"><span> ✅ </span>Vilka är ägarna<br />  bakom Riskfritt.se?</h3>
                        </div>
                        <div className={classNames([
                            "play-Bodyse",
                            {'collapse-Tab':!openedQuestion.has('b-b3')}
                        ])} id="b-b3">
                            <div class="play-Bodytxt acc-Bottomse">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature </p>
                            </div>
                        </div>
                    </div>
                    <div class="play-Boxtxt">
                        <div onClick={() => {
                            let questionsClone = new Set(openedQuestion);
                            if (questionsClone.has('b-b4')) {
                                questionsClone.delete('b-b4');
                            }
                            else {
                                questionsClone.add('b-b4');
                            }
                            setOpenedQuestion(questionsClone);
                        }}  class="play-Header">
                            <h3 className={classNames([                                
                                {'collapsed':!openedQuestion.has('b-b4')}
                            ])} data-toggle="collapse" data-target="#b-b4"><span> ✅ </span>Hur kontaktar jag Riskfritt.se?</h3>
                        </div>
                        <div className={classNames([
                            "play-Bodyse",
                            {'collapse-Tab':!openedQuestion.has('b-b4')}
                        ])} id="b-b4">
                            <div class="play-Bodytxt acc-Bottomse">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QA_Accordion;
