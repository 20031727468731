import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import Slider from 'react-slick';
import './styles.scss';
import IMG_1 from '../../../assets/images/img1.jpg';
import IMG_2 from '../../../assets/images/img2.jpg';
import IMG_3 from '../../../assets/images/img3.jpg';
import { TEXT_CONST } from '../../../shared';

const settings = {
    dots: true,

    infinite: false,
  
    speed: 1000,
  
    slidesToShow: 3,
  
    slidesToScroll: 1,
  
     autoplay: false,
     arrows:false,
  
    autoplaySpeed: false,
  
    responsive: [
  
      {
  
        breakpoint: 1200,
  
        settings: {
  
          slidesToShow: 3,
  
        }
  
      },
  
      {
  
        breakpoint:991,
  
        settings: {
  
          slidesToShow: 3, 
  
        }
  
      },
  
       {
  
        breakpoint:767,
  
        settings: {
  
          slidesToShow: 2, 
  
        }
  
      },
  
      {
  
        breakpoint: 580,
  
        settings: {
  
          slidesToShow: 1, 
  
        }
  
      }
  
      // You can unslick at a given breakpoint now by adding:
  
      // settings: "unslick"
  
      // instead of a settings object
  
    ]
};

const NewsList = ({
    params,
}) => (
        <div class="news-Rmainse">
            <div class="title-Betse">
                <h2>Senaste nytt om svenska casinon</h2>
                <p> Läs de senaste nyheterna om olika svenska casinon
            och deras erbjudanden, samt läs senaste nyheterna om Riskfritt.se.</p>
            </div>
                                     
            <div class="inner-Bnewse">
                <div class="row center1"><div class="col-lg-12">
                 <Slider {...settings}> 
                    <div class="col-lg-12">
                        <div class="news-Wrap">
                            <div class="news-Topimg"><img src={IMG_1} alt="..." /></div>
                            <div class="box-Centersec">
                                <div class="nwbox-title">
                                    <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                                </div>
                                <div class="news-Boxdate">
                                    <div class="pub-Mainse">
                                        <div class="pub-Mainsec">
                                            <div class="pub-Leftsec">
                                                <h4><span> Amdin318 </span> </h4>
                                            </div>
                                            <div class="pub-Right">
                                                <h4><span class="right_Atinfo"><i class="fa fa-calendar" aria-hidden="true"></i> 04-11-20</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="news-Boxtxt">
                                    <p>Här under har vi listat de vanligaste frågorna som personer har undrat hitta det  support genom att klicka på den runda ikonen till höger. </p>
                                </div>
                                <div class="read-Bnwsec"> <a class="primary-Gbtn full-Width" href="https://ukonlinecasinos2021.com/lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry-6/" rel="nofollow">LÄS MER</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="news-Wrap">
                            <div class="news-Topimg"><img src={IMG_2} alt="..." /></div>
                            <div class="box-Centersec">
                                <div class="nwbox-title">
                                    <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                                </div>
                                <div class="news-Boxdate">
                                    <div class="pub-Mainse">
                                        <div class="pub-Mainsec">
                                            <div class="pub-Leftsec">
                                                <h4><span> Amdin318 </span> </h4>
                                            </div>
                                            <div class="pub-Right">
                                                <h4><span class="right_Atinfo"><i class="fa fa-calendar" aria-hidden="true"></i> 04-11-20</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="news-Boxtxt">
                                    <p>Här under har vi listat de vanligaste frågorna som personer har undrat hitta det  support genom att klicka på den runda ikonen till höger.</p>
                                </div>
                                <div class="read-Bnwsec"> <a class="primary-Gbtn full-Width" href="https://ukonlinecasinos2021.com/lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry-5/" rel="nofollow">LÄS MER</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="news-Wrap">
                            <div class="news-Topimg"><img src={IMG_3} alt="..." /></div>
                            <div class="box-Centersec">
                                <div class="nwbox-title">
                                    <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                                </div>
                                <div class="news-Boxdate">
                                    <div class="pub-Mainse">
                                        <div class="pub-Mainsec">
                                            <div class="pub-Leftsec">
                                                <h4><span> Amdin318 </span> </h4>
                                            </div>
                                            <div class="pub-Right">
                                                <h4><span class="right_Atinfo"><i class="fa fa-calendar" aria-hidden="true"></i> 04-11-20</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="news-Boxtxt">
                                    <p>Här under har vi listat de vanligaste frågorna som personer har undrat hitta det  support genom att klicka på den runda ikonen till höger. </p>
                                </div>
                                <div class="read-Bnwsec"> <a class="primary-Gbtn full-Width" href="https://ukonlinecasinos2021.com/lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry-4/" rel="nofollow">LÄS MER</a></div>
                            </div>
                        </div>
                        </div>
                    </Slider></div>
                </div>
            </div>
            <div class="load-Morese"><a href="javascript:void(0);" class="primary-Gbtn large-Btn">LADDA FLER NYHETER</a> </div>
        </div>
    );

export default NewsList;
