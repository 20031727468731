import React,{useState} from 'react';
import { Modal } from 'react-bootstrap';
import { IMAGES,EMAIL_REGX } from '../../../shared';
import './styles.css';

const LoginModal = ({
    onClose = () => { },
    onOpenSignup = () => { },
    show,
}) => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    return(
        <Modal
            onHide={() => {
                setErrorMessage('');
                onClose();
            }}
            show={show}
            size='lg'
        >
            <div class=" modal-Wrapse">
                <div class="close-Modse"><a onClick={() => {
                setErrorMessage('');
                onClose();
            }} href="#">+</a></div>
                <div class="login-Wrapse">
                    <div class="row no-gutters">
                        <div class="col-lg-7">
                            <div class="left-Logse">
                                <h3>Logga in</h3>
                                <p>Välkommen tillbaka till Riskfritt.se, passa på att jämföra, läsa recensionerna och se vad som passar dig bäst innan du
bestämmer dig för att spela på ett casino. </p>
                                <div class="inputGrp"><span class="fa fa-envelope env"></span>
                                    <input type="text" class="form-control log-Frm" placeholder="Ange din e-postadress som du registrerade ditt konto med" onChange={event => {
                                        setErrorMessage('');
                                        setEmail(event.target.value)
                                    }} />
                                </div>
                                {!!errorMessage && <label className={'error-txt'}>{errorMessage}</label>}
                                <div class="lg-Btnse">
                                    <a onClick={() => {
                                        console.log('email', EMAIL_REGX.test(email))
                                        if (!email) {
                                            setErrorMessage('Email is required.');
                                            return;
                                        }
                                        else if (!EMAIL_REGX.test(email)) {                                            
                                            setErrorMessage('Email is invalid.');
                                            return;
                                        }
                                    }} class="primary-Gbtn full-Width dark-Green "><i><img src={IMAGES.BANK_ID} alt="..." /></i>LOGGA IN<span>Logga in säkert med BankID</span> </a>
                                </div>
                                <div class="frgtSec"><p>Har du inget konto?<a onClick={onOpenSignup} > Skapa ett här!</a></p></div>
                            </div>


                        </div>
                        <div class="col-lg-5 d-none d-lg-flex"><div class="right-Logse">
                            <h3>Är det första gången<br />
                            du loggar in?</h3>
                            <div class="binny-Mose"><img src={IMAGES.BAN} alt="..." />

                            </div>
                            <p>
                                Ingen fara, det är väldigt enkelt att
                                logga in. Du behöver endast ange din e-postadress som du registrerade dig med och licka på Logga in-knappen. Sedan öppnar du din BankID app
                                och bekräftar att du vill logga in.
                            </p>

                        </div></div>
                    </div>

                </div>



            </div>
        </Modal >
    );
}
export default LoginModal;
