import React from 'react';
import './styles.scss';
import { ICONS, IMAGES, TEXT_CONST } from '../../../shared';

const Footer = ({ params }) => (
  <div>
    <div class="lay-wrap">
      <div class="bot-Layse"><img src={IMAGES.FOOTER_LAYER} alt="..." /></div>
    </div>
    <footer>
      <div class="top-Footsec">
        <div class="container">
          <div class="inner-Topfoot">
            <div class="row">
              <div class="col-md-12">
                <div class="footer-Logosec"><a href="javascript:void(0);"><img src={IMAGES.LOGO_2} alt="logo" /></a></div>
              </div>
              <div class="col-md-12 d-none d-lg-block">
                <div class="foot-Toplkse">
                  <ul>
                    <li><a href="javascript:void(0);">OM OSS</a></li>
                    <li><a href="javascript:void(0);">HUR FUNGERAR DET</a></li>
                    <li><a href="javascript:void(0);"> LIVE SUPPORT </a></li>
                    <li><a href="javascript:void(0);"> KONTAKTA OSS</a></li>
                  </ul>
                  <ul class="bottom-Rnav">
                    <li><a href="javascript:void(0);">ANVÄNDARVILLKOR</a></li>
                    <li><a href="javascript:void(0);">PRIVACY POLICY</a></li>
                    <li><a href="javascript:void(0);">COOKIES</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-12">
                <div class="foot-Wraptx">
                  <div class="mobile-Rfooter">
                    <div class="mobile-Rfinse ">
                      <div class="row d-lg-none">
                        <div class="col-6">
                          <div class="foot-Robse">
                            <div class="fot-Rtitlese">
                              <h3> MER OM RISKFRITT.SE</h3>
                            </div>
                            <ul>
                              <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> OM OSS</a></li>
                              <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i>HUR FUNGERAR DET</a></li>
                              <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> LIVE SUPPORT </a></li>
                              <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i> KONTAKTA OSS</a></li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="foot-Robse">
                            <div class="fot-Rtitlese">
                              <h3>VÄNLIGEN LÄS IGENOM</h3>
                            </div>
                            <ul>
                              <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i>ANVÄNDARVILLKOR</a></li>
                              <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i>PRIVACY POLICY</a></li>
                              <li><a href="javascript:void(0);"><i class="fa fa-angle-double-right"></i>COOKIES</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fot-Rwrapse">
                      <div class="fot-Rlogose">
                        <div class="fot-Rtitlese">
                          <h3>VI ANVÄNDER OSS UTAV</h3>
                        </div>
                        <ul>
                          <li><a href="#"><img src={IMAGES.FT_LOGO_1} alt="..." /></a></li>
                          <li><a href="#"><img src={IMAGES.BRITE} alt="..." /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-Footer">
        <div class="container">
          <div class="fot-Botsec">
            <ul class="mob-Stes  d-lg-none">
              <li class="midse"><span>18+</span> Spelar du för mycket? Kontakta <a href="#"> <img src={IMAGES.BOT_IMG_1} alt="..." /> </a></li>
            </ul>
            <ul>
              <li class="bor-Rno">2021 © <a href="#" >Riskfritt.se</a></li>
              <li class="midse d-none d-lg-block"><span>18+</span> Spelar du för mycket? Kontakta <a href="#"> <img src={IMAGES.BOT_IMG_1} alt="..." /> </a></li>
              <li> <a href="#" > <i><img src={IMAGES.FT_ICON} alt="..." /></i> Spelinspektionen</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
);

export default Footer;
