export const TEXT_CONST = {
  // Home Screen Nav bar text
  WELCOME: "Välkommen ",
  MONEY: (money) => `${money} KR`,

  //SITE_INFO
  YOU_HAVE_MONEY: (money) => `Du har: ${TEXT_CONST.MONEY(money)}`,
  USER_NAME: (name) => `${name}`,
  WITHDRAW_MONEY: "Ta ut dina pengar här",
  GET_BACK_UPTO: (sekAmount) => `FÅ TILLBAKA UPP TILL ${sekAmount} SEK`,
  ON_YOUR_FIRST_DEPOSIT: "PÅ DIN FÖRSTA INSÄTTNING",
  USER_WELCOME_MSG: "Välkommen till Riskfritt.se,",
  SITE_INFO:
    "Du kan nu jämföra och läsa recensioner om alla nya och bästa casinon online som innehar en svensk casino licens",
  OFFERS_INFO: (sekAmount) =>
    `Att spela och vinna på ett casino handlar oftast om ren tur och alla har inte turen att vinna. Därför erbjuder vi alla våra medllemmar som gör sin första insättning på ett casino att få tillbaka upp till ${sekAmount} KR från oss, vilket ger dig möjligheten att testa flera olika casinon och ändå få dina pengar tillbaka om oturen slår till.`,
  SEE_OUR_THIS_MUCH_REVIEWS: (reviewCount) => `Se våra ${reviewCount} omdömen`,
  VERIFICATION: "VERIFIERING",
  VERIFY_N_MONEY_BACK: "Verifiera & få pengar tillbaka",

  //HOW DOES IT WORK
  HOW_DOES_IT_WORK: "Hur fungerar det?",
  HOW_IT_WORK_CARDS: [
    {
      id: 1,
      firstHalf: "Registrera dig",
      secondHalf: "med BankID",
      DETAILS:
        "Det är enkelt och gratis att skapa ett konto hos oss, klicka på Registrera dig-knappen, fyll i namn och epost så verifierar du dig själv med BankID efter du har gjort det så är du en registrerad medlem hos oss!",
    },
    {
      id: 2,
      firstHalf: "Välj ett casino",
      secondHalf: "att spela på",
      DETAILS:
        "I topplistan nedan har vi tagit fram både nya och de bästa casinon som innehar en svensk licens. Läs och välj ett casino som du inte har spelat på tidigare, klicka på gröna knappen och gör din första insättning på casinot.",
    },
    {
      id: 3,
      firstHalf: "Hade du otur att",
      secondHalf: "förlora pengarna? ",
      DETAILS:
        "Ingen fara, vi är här för att hjälpa! Gå till menyn Verifiering. Där kan du skicka in en bild på att du har gjort en insättning på casinot och förlorat det. Vi granskar det och betalar sedan tillbaka summan som du förlorade!",
    },
    {
      id: 4,
      firstHalf: "Automatiska",
      secondHalf: "utbetalningar",
      DETAILS:
        "När granskningen har godkänds så uppdateras din kontobalans automatiskt. Där kan du sedan välja att göra ett uttag. Verifiera dig med BankID och pengarna är tillbaka på ditt personliga bankkonto på några minuter.",
    },
  ],

  //START PLAYING
  COMPARE_SELECT_N_START_PLAYING: "Jämför, välj och börja spela!",
  HOW_TO_START_N_PLAY_INFO:
    "Välj det casino som passar dig bäst, kolla vad de har att erbjuda, läs recensionerna och kolla hur mycket pengar du kan få tillbaka på din första insättning om du skulle ha oturen att förlora dem. Det är viktigt att du har registrerat ett konto med hjälp av BankID hos oss på Riskfritt.se innan du börjar spela, annars kan du inte få tillbaka några pengar från oss om du skulle förlora pengarna du gjorde på din första insättning. På den gröna knappen kan du se hur mycket du kan få tillbaka på din första insättning, och detta erbjudande gäller endast på din första insättning och om du är en ny kund hos casinot du väljer att spela på.",

  //LATEST NEWS
  LATEST_NEWS: "Senaste nytt om svenska casinon",
  READ_NEWS:
    "Läs de senaste nyheterna om olika svenska casinon och deras erbjudanden, samt läs senaste nyheterna om Riskfritt.se.",

  //LATEST MEMBERS
  LATEST_MEMBERS: "Senaste medlemmarna som har fått",
  WHO_RECEIVED_MONEY: "pengar tillbaka och utbetalt från Riskfritt.se",
  WHY_LATEST_MEMBERS:
    "Vi på Riskfritt.se är väldigt transparenta och visar mer än gärna alla senaste medlemmar som har fått sina verifkationer godkända. Samt visar medlemmar som har begärt en utbetalning och fått den inom några minuter till hens personliga bankkonto. Av säkerhetsskäl så kan vi endast visa förnamnet på personen i fråga.",

  //FOOTER
  WIN_OR_MONEY_BACK: "VINN ELLER FÅ PENGARNA TILLBAKA",
  LOGO_TEXT: "Riskfritt.com",
  FOOTER_HEADINGS: [
    {
      title: "OM OSS",
    },
    {
      title: "HUR FUNGERAR DET",
    },
    {
      title: "LIVE SUPPORT",
    },
    {
      title: "KONTAKTA OSS",
    },
  ],
  FOOTER_SUB_HEADINGS: [
    {
      title: "ANVÄNDARVILLKOR ",
    },
    {
      title: "PRIVACY POLICY",
    },
    {
      title: "COOKIES",
    },
  ],
  PLAY_TOO_CONTACT: "Spelar Du For Mycket? Kontakta",
  GAME_INSPECTOR: "Spelinspektonen",
};