import './App.scss';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import RootRouter from './routes/1.0';
function App() {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <RootRouter />
      </Provider>
    </PersistGate>
  );
}

export default App;
