import React from 'react';
import './styles.scss';

const LoginScreen = ({
    params,
}) => (
        <div>
            Login Screen
        </div >
    );

export default LoginScreen;