import React,{useState} from 'react';
import { Modal } from 'react-bootstrap';
import { IMAGES, EMAIL_REGX, NAME_REGX } from '../../../shared';
import './styles.css';

const BANKID_API_KEY = process.env.REACT_APP_BANKID_API_KEY;
const BANKID_AUTH_SERVICE_KEY = process.env.REACT_APP_BANKID_AUTH_SERVICE_KEY;
const SignUpModal = ({
    show,
    getRedirectUrl,
    onOpenLogin = () => { },
    onClose = () => { }
}) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');

    return(
        <Modal
            onHide={() => {
                setEmailError('');
                setNameError('');
                onClose();
            }}
            show={show}
            size='lg'
        > <div class="open_Mod09">
                <div class="close-Modse"><a onClick={() => {
                setEmailError('');
                setNameError('');
                onClose();
            }} href="#">+</a></div>
                <div class="login-Wrapse sign-Wrapse">
                    <div class="row no-gutters">
                        <div class="col-lg-7">
                            <div class="left-Logse">
                                <h3>Registrera dig</h3>
                                <p>Det är både enkelt och gratis att registrera sig på Riskfritt.se. När du har registrerat dig kan du logga in med din e-postadress
                                och BankID för att ta del av våra fördelar där du kan få tillbaka upp
              till 500 KR på din första insättning, när du spelar på ett nytt casino.</p>
                                <div class="lab-Inpt">
                                    <h3>Ange din e-post</h3>
                                </div>
                                <div class="inputGrp"><span class="fa fa-envelope env"></span>
                                    <input type="text" class="form-control log-Frm" placeholder="Ange din e-postadress som du registrerade ditt konto med" onChange={event => {
                                        setEmailError('');
                                        setEmail(event.target.value)
                                    }} />
                                </div>
                                {!!emailError && <label className={'error-txt'}>{emailError}</label>}
                                <div class="sign_upse">
                                    <div class="row">
                                        <div class="col-lg-7">
                                            <div class="lab-Inpt">
                                                <h3>Ange ditt namn</h3>
                                            </div>
                                            <div class="inputGrp"><span class="fa fa-envelope env"></span>
                                                <input type="text" class="form-control log-Frm" placeholder="Ange Förnamn och Efternamn" onChange={event => {
                                                    setNameError('');
                                                    setName(event.target.value)
                                                }} />
                                            </div>
                                            {!!nameError && <label className={'error-txt'}>{nameError}</label>}
                                        </div>
                                        <div class="col-lg-5">
                                            <div class="lab-Inpt">
                                                <h3>Ange födelsedatum</h3>
                                            </div>
                                            <div class="row right_datse">
                                                <div class="col-4">
                                                    <div class="inputGrp">
                                                        <input type="text" class="form-control log-Frm" placeholder="DD" />
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="inputGrp">
                                                        <input type="text" class="form-control log-Frm" placeholder="MM" />
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="inputGrp">
                                                        <input type="text" class="form-control log-Frm" placeholder="ÅÅÅÅ" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chk-Bxse">
                                    <div class="btn-group-toggle" data-toggle="buttons">
                                        <label class="btn">
                                            <input type="checkbox" checked autocomplete="off" />
                                            Jag har läst och godkänt <a href="#" >Användarvillkoren</a> </label>
                                    </div>
                                </div>
                                <div class="lg-Btnse"> <a
                                    onClick={() => {
                                        console.log('email', EMAIL_REGX.test(email))
                                        if (!email) {
                                            setEmailError('Email is required.');
                                            return;
                                        }
                                        else if (!EMAIL_REGX.test(email)) {                                            
                                            setEmailError('Email is invalid.');
                                            return;
                                        }
                                        if (!name) {
                                            setNameError('Name is required.');
                                            return;
                                        }
                                        else if (!NAME_REGX.test(name)) {                                            
                                            setNameError('Name is invalid.');
                                            return;
                                        }
                                        // fetch(`https://client.grandid.com/json1.1/FederatedLogin?apiKey=${BANKID_API_KEY}&authenticateServiceKey=${BANKID_AUTH_SERVICE_KEY}`, {
                                        //     headers: {                                                
                                        //         "Content-Type":'application/json, charset=utf8'
                                        //     }
                                        // }).then((res) => {
                                        //     console.log('res =====',res)
                                        // }).catch(e=>console.log('err ======',e))
                                        // getRedirectUrl((response) => {
                                        //     console.log('success response',response)
                                        // },
                                        //     (err) => {
                                        //     console.log('err response',err)
                                        // })
                                    }}
                                    class="primary-Gbtn full-Width dark-Green "><i><img src={IMAGES.BANK_ID} alt="..." /></i>REGISTRERA DIG<span>Bekräfta din registrering med BankID</span> </a> </div>
                                <div class="frgtSec">
                                    <p>Har du inget konto?<a onClick={onOpenLogin} href="#" > Skapa ett här!</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 d-none d-lg-flex">
                            <div class="right-Logse">
                                <h3>Är det första gången<br />
                                    du loggar in?</h3>
                                <div class="binny-Mose"><img src={IMAGES.BAN} alt="..." /> </div>
                                <p> Ingen fara, det är väldigt enkelt att
                                logga in. Du behöver endast ange din e-postadress som du registrerade dig med och licka på Logga in-knappen. Sedan öppnar du din BankID app
              och bekräftar att du vill logga in. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );}

export default SignUpModal;
